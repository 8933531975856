let menuItems = {};
let cuenta = localStorage.getItem('cuenta');
const decoded = JSON.parse(localStorage.getItem('user') || '{}');
if (cuenta == 'Demo') {
  menuItems = {
    items: [
      {
        id: 'open-close',
        title: '',
        type: 'menu-icon',
        icon: 'feather icon-menu',
      },
      {
        id: 'navigation',
        title: 'Navegación',
        type: 'group',
        icon: 'icon-navigation',
        children: [
          {
            id: 'dashboard',
            title: 'Reportes',
            type: 'collapse',
            icon: 'feather icon-activity',
            children: [
              {
                id: 'sales',
                title: 'Ventas',
                type: 'item',
                url: '/app/dashboard/ventas',
              },
              {
                id: 'inventory',
                title: 'Inventarios',
                type: 'item',
                url: '/app/dashboard/inventory',
              },
              {
                id: 'Disp',
                title: 'Disp. Inventarios',
                type: 'item',
                url: '/app/dashboard/disponibilidad-inventarios',
              },
              {
                id: 'promo',
                title: 'Promotoria',
                type: 'item',
                url: '/app/dashboard/promotoria',
              },
              {
                id: 'rutas',
                title: 'Rutas',
                type: 'item',
                url: '/app/dashboard/rutas',
              },
              {
                id: 'Anaqueles',
                title: 'Anaqueles',
                type: 'item',
                url: '/app/dashboard/anaqueles',
              },
              // {
              //   id: 'ventas-2',
              //   title: 'Ventas 2',
              //   type: 'item',
              //   url: '/app/dashboard/general',
              // },
              // {
              //   id: 'cruda',
              //   title: 'Base Cruda',
              //   type: 'item',
              //   url: '/app/reportes/base-cruda',
              // },
            ]
          },

          {
            id: 'layout',
            title: 'Soporte',
            type: 'collapse',
            icon: 'feather icon-help-circle',
            children: [
              {
                id: 'widget-chart',
                title: 'Soporte',
                type: 'item',
                url: '/sample-page'
              }
            ]
          },

          {
            id: 'widgets',
            title: 'Manuales',
            type: 'collapse',
            icon: 'feather icon-layers',
            children: [
              {
                id: 'widget-statistic',
                title: 'Manuales',
                type: 'item',
                url: '/editor/ck-editor/ck-document'
              },
            ]
          },

          // {
          //   id: 'users',
          //   title: 'Administración',
          //   type: 'collapse',
          //   icon: 'feather icon-package',
          //   children: [
          //     {
          //       id: 'user-profile',
          //       title: 'Portáles',
          //       type: 'item',
          //       url: '/project-crm/pc-dashboard',
          //       breadcrumbs: false
          //     },
          //     {
          //       id: 'user-cards',
          //       title: 'Admin. de Perfíles',
          //       type: 'item',
          //       url: '/users/user-cards'
          //     },
          //   ]
          // }
        ]
      },
    ]
  };
} else if (decoded?.email?.endsWith('evolve.com.mx') && decoded?.interno == 1) {
  menuItems = {
    items: [
      {
        id: 'open-close',
        title: '',
        type: 'menu-icon',
        icon: 'feather icon-menu',
      },
      {
        id: 'navigation',
        title: 'Navegación',
        type: 'group',
        icon: 'icon-navigation',
        children: [
          {
            id: 'dashboard',
            title: 'Reportes',
            type: 'collapse',
            icon: 'feather icon-activity',
            children: [
              {
                id: 'sales',
                title: 'Ventas',
                type: 'item',
                url: '/app/dashboard/ventas',
              },
              {
                id: 'Inventarios',
                title: 'Inventarios',
                type: 'item',
                url: '/app/dashboard/inventory',
              },
              {
                id: 'Promotoria',
                title: 'Promotoria',
                type: 'item',
                url: '/app/dashboard/promotoria',
              },
              // {
              //   id: 'Rutas',
              //   title: 'Rutas',
              //   type: 'item',
              //   url: '/app/dashboard/rutas',
              // },
              {
                id: 'Salud',
                title: 'Salud Inventarios',
                type: 'item',
                url: '/app/dashboard/salud-inventarios',
              },
              // {
              //   id: 'Disp',
              //   title: 'Disp. Inventarios',
              //   type: 'item',
              //   url: '/app/dashboard/disponibilidad-inventarios',
              // },
              {
                id: 'Scorecard',
                title: 'Scorecard',
                type: 'item',
                url: '/app/dashboard/scorecard-VSPT',
              },
              // {
              //   id: 'General',
              //   title: 'General',
              //   type: 'item',
              //   url: '/app/dashboard/general',
              // },
              {
                id: 'Base',
                title: 'Base Cruda',
                type: 'item',
                url: '/app/reportes/base-cruda',
              },
              {
                id: 'estatus',
                title: 'Estatus cadenas',
                type: 'item',
                url: '/app/reportes/estatus-cadenas',
              },
              {
                id: 'comercial',
                title: 'Tablero comercial',
                type: 'item',
                url: '/app/reportes/tablero-comercial',
              },
              {
                id: 'cobertura',
                title: 'Desempeño geográfico',
                type: 'item',
                url: '/app/reportes/analisis-cobertura',
              },
              // {
              //   id: 'ventas-2',
              //   title: 'Ventas 2',
              //   type: 'item',
              //   url: '/app/dashboard/general',
              // }
            ]
          },

          {
            id: 'layout',
            title: 'Soporte',
            type: 'collapse',
            icon: 'feather icon-help-circle',
            children: [
              {
                id: 'widget-chart',
                title: 'Soporte',
                type: 'item',
                url: '/sample-page'
              }
            ]
          },

          {
            id: 'widgets',
            title: 'Manuales',
            type: 'collapse',
            icon: 'feather icon-layers',
            children: [
              {
                id: 'widget-statistic',
                title: 'Manuales',
                type: 'item',
                url: '/editor/ck-editor/ck-document'
              },
            ]
          },

          {
            id: 'users',
            title: 'Administración',
            type: 'collapse',
            icon: 'feather icon-package',
            children: [
              {
                id: 'user-profile',
                title: 'Portáles',
                type: 'item',
                url: '/app/portales',
                breadcrumbs: false
              },
              {
                id: 'user-cards',
                title: 'Admin. de Perfíles',
                type: 'item',
                url: '/users/user-cards'
              },
              {
                id: 'executions',
                title: 'Ejecuciones',
                type: 'item',
                url: '/app/ejecuciones',
                breadcrumbs: false
              },
            ]
          }
        ]
      },
    ]
  };
} else {
  menuItems = {
    items: [
      {
        id: 'open-close',
        title: '',
        type: 'menu-icon',
        icon: 'feather icon-menu',
      },
      {
        id: 'navigation',
        title: 'Navegación',
        type: 'group',
        icon: 'icon-navigation',
        children: [
          {
            id: 'dashboard',
            title: 'Reportes',
            type: 'collapse',
            icon: 'feather icon-activity',
            children: [
              {
                id: 'sales',
                title: 'Ventas',
                type: 'item',
                url: '/app/dashboard/ventas',
              },
              {
                id: 'Inventarios',
                title: 'Inventarios',
                type: 'item',
                url: '/app/dashboard/inventory',
              },
              {
                id: 'Promotoria',
                title: 'Promotoria',
                type: 'item',
                url: '/app/dashboard/promotoria',
              },
              // {
              //   id: 'Rutas',
              //   title: 'Rutas',
              //   type: 'item',
              //   url: '/app/dashboard/rutas',
              // },
              {
                id: 'Salud',
                title: 'Salud Inventarios',
                type: 'item',
                url: '/app/dashboard/salud-inventarios',
              },
              // {
              //   id: 'Disp',
              //   title: 'Disp. Inventarios',
              //   type: 'item',
              //   url: '/app/dashboard/disponibilidad-inventarios',
              // },
              {
                id: 'Scorecard',
                title: 'Scorecard',
                type: 'item',
                url: '/app/dashboard/scorecard-VSPT',
              },
              // {
              //   id: 'General',
              //   title: 'General',
              //   type: 'item',
              //   url: '/app/dashboard/general',
              // },
              {
                id: 'Base',
                title: 'Base Cruda',
                type: 'item',
                url: '/app/reportes/base-cruda',
              },
              {
                id: 'estatus',
                title: 'Estatus cadenas',
                type: 'item',
                url: '/app/reportes/estatus-cadenas',
              },
              {
                id: 'comercial',
                title: 'Tablero comercial',
                type: 'item',
                url: '/app/reportes/tablero-comercial',
              },
              {
                id: 'cobertura',
                title: 'Desempeño geográfico',
                type: 'item',
                url: '/app/reportes/analisis-cobertura',
              }
            ]
          },

          {
            id: 'layout',
            title: 'Soporte',
            type: 'collapse',
            icon: 'feather icon-help-circle',
            children: [
              {
                id: 'widget-chart',
                title: 'Soporte',
                type: 'item',
                url: '/sample-page'
              }
            ]
          },

          {
            id: 'widgets',
            title: 'Manuales',
            type: 'collapse',
            icon: 'feather icon-layers',
            children: [
              {
                id: 'widget-statistic',
                title: 'Manuales',
                type: 'item',
                url: '/editor/ck-editor/ck-document'
              },
            ]
          },

          {
            id: 'users',
            title: 'Administración',
            type: 'collapse',
            icon: 'feather icon-package',
            children: [
              {
                id: 'user-profile',
                title: 'Portáles',
                type: 'item',
                url: '/app/portales',
                breadcrumbs: false
              },
              {
                id: 'user-cards',
                title: 'Admin. de Perfíles',
                type: 'item',
                url: '/users/user-cards'
              },
            ]
          }
        ]
      },
    ]
  };
}

export default menuItems;
